import { Singleton } from "../Resource";
import get from "lodash/get";
import snakeCase from "lodash/snakeCase";
import startCase from "lodash/startCase";
import groupBy from "lodash/groupBy";
import { getUserData } from "@utils";
import navigation from "@src/navigation/vertical";
import { Navigate } from "react-router-dom";
import AuthUser, { AuthClass } from "./AuthService";
import ModuleService from "./ModuleService";
import find from "lodash/find";
import DynamicRoutesService from "../../DynamicForm/services/DynamicRoutesService";
import { Forms } from "../../DynamicForm/assets/SVG";

class Service extends AuthClass {
  defaultFirstRouteName = "";
  routes = [];
  sideMenu = [];
  isArabic = false;
  constructor() {
    super(arguments);

    this.isArabic =
      window.localStorage.getItem("direction")?.toString?.() === "true";
  }

  async init(routes) {
    await ModuleService.getAllModules();

    this.routes = this.getAvailableRoutes(routes);
    this.sideMenu = this.updateSideMenu(navigation);
    // Add default root route (/) component in the routes
    try {
      let { formBuilderSideMenu, formBuilderRoutes } =
        await DynamicRoutesService.getForms();

      let newMenuItems = groupBy(
        formBuilderSideMenu.filter((form) => form.meta_data?.menuName),
        (item) => get(item, "meta_data.menuName", "").toLowerCase()
      );
      newMenuItems = Object.entries(newMenuItems);

      if (newMenuItems.length) {
        newMenuItems.forEach(([menuName, menu]) => {
          let childToPush = menu;
          if (!this.isAdmin && !this.isHoldCo ) {
            childToPush = childToPush.filter((form) =>
              form?.meta_data?.roles?.includes?.(this.role)
            );
            childToPush = childToPush.filter((form) => {
              let regions = form?.meta_data?.regions
                ?.map?.((region) => region.label || region.name)
                ?.filter((i) => i);

              if (!regions?.length) return true;
              return regions.reduce((r, c) => {
                r = r || this.regionNames.includes(c);
                return r;
              }, false);
            });
          }

          let navToUpdt = this.sideMenu.filter(
            (menu) =>
              (menu?.title + "").toLowerCase() === (menuName + "").toLowerCase()
          );

          if (navToUpdt.length > 0) {
            navToUpdt = navToUpdt[0];
            if (navToUpdt) {
              navToUpdt.onlyFor = [this.role];
              navToUpdt.children.push(...childToPush);
            }
          } else if ((menuName + "").trim() == "root") {
            if (childToPush?.length) {
              menu.forEach((_menu) => {
                if (_menu?.title) {
                  let menuPush = {
                    id: snakeCase(_menu.title),
                    slug: snakeCase(_menu.title),
                    title: _menu.title,
                    text: _menu.title,
                    onlyFor: [this.role],
                    icon: () => Forms,
                    navLink: `/${_menu._id}/Form-Builder`,
                  };

                  //Push on second last place
                  this.sideMenu.splice(this.sideMenu.length - 1, 0, menuPush);
                }
              });
            }
          } else {
            if (childToPush.length) {
              let menuPush = {
                id: snakeCase(menuName),
                slug: snakeCase(menuName),
                title: startCase(menuName),
                text: startCase(menuName),
                children: childToPush,
                onlyFor: [this.role],
                icon: () => Forms,
              };

              if (childToPush?.length) {
                this.sideMenu.push(menuPush);
              }
            }
          }
        });
      }
      const navToUpdate = find(this.sideMenu, { id: "forms" });
      this.routes.push(...formBuilderRoutes);

      formBuilderSideMenu = formBuilderSideMenu.filter(
        (form) => !form.meta_data?.menuName
      );
      if (navToUpdate) {
        if (!this.isAdmin && !this.isHoldCo) {
          formBuilderSideMenu = formBuilderSideMenu.filter((form) =>
            form?.meta_data?.roles?.includes?.(this.role)
          );
        }
        navToUpdate.children.push(...formBuilderSideMenu);
      }

      this.sideMenu = this.sideMenu.filter((menu) => {
        if (menu?.forRegions?.length) {
          if (!this.isAdmin && !this.isHoldCo) {
            return menu?.forRegions?.reduce(
              (r, c) => r || this.regionNames.includes(c),
              false
            );
          }
        }

        if (menu?.children?.length === 0) {
          return false;
        }

        return true;
      });
    } catch (error) {
      console.log("err", error);
    }
    this.addFirstRedirectRoute();
  }

  get allFormRoutes() {
    return this.routes.filter((r) => r.isForm).map((r) => r.path);
  }

  addFirstRedirectRoute() {
    // Get url of first child from the side menu
    let parentRoute = get(this.sideMenu, "0.children", []).filter(
      ({ isDisable }) => !isDisable
    );
    let route = get(parentRoute, "0.navLink", get(this.sideMenu, "0.navLink"));

    if (this.routes?.length && route) {
      this.routes.push({
        element: <Navigate to={route} />,
        path: "/",
      });
    }
  }

  getAvailableRoutes(routes) {
    return routes.filter((r) => {
      //  Admin can have access to all routes

      if (r.onlyFor) {
        return r.onlyFor?.includes?.(this.role);
      }

      if (this.isAdmin) {
        return true;
      }

      let check = this.availableModules.includes(r.slug?.toLowerCase());
      check = check || this.slugForRoles.includes(r.slug?.toLowerCase());

      //check dashboard routes
      let dashboardRoute = r.path.split("/").includes("Dashboard");
      if (dashboardRoute && (this.isPharmacist || this.isAgent)) {
        check = false;
      }

      if (this.isModerator && r.isForm) {
        check = false;
      }

      return check || r.isAuth;
    });
  }

  isAvailableInRoutes(routeName) {
    // Support all routes including singular and plural
    let menuTitle = [
      routeName,
      routeName + "s",
      routeName?.substr(0, routeName.length - 1),
    ];
    if (!AuthUser.isFacility) {
      if (routeName == "settings") return true;
      if (routeName == "hajj") return true;
    }
    // Only available modules are visiable in the side menu
    return (
      this.availableModules.includes(menuTitle[0]) ||
      this.availableModules.includes(menuTitle[1]) ||
      this.availableModules.includes(menuTitle[2])
    );
  }

  updateSettingRoutes(menuData) {
    let SettingRoute = [];
    if (this.isAdmin) {
      return menuData;
    }

    if (menuData) {
      let settings = get(
        menuData.filter(({ id }, i) => id === "settings"),
        "0"
      );

      if (settings) {
        SettingRoute = settings.children.filter((sroute) => {
          return this.availableModules.includes(sroute.title.toLowerCase());
        });
      }

      if (SettingRoute?.length) {
        settings.children = SettingRoute;
      } else {
        menuData = menuData.filter(({ id }, i) => id !== "settings");
      }
    }
    return menuData;
  }

  removeModuleRoutes(menuData) {
    return menuData.filter((menu) => {
      // All routes are accessable by the admin
      // Side menu will show all menu for admin
      if (menu.onlyFor) {
        return menu.onlyFor?.includes?.(this.role);
      }

      if (this.isAdmin) {
        return true;
      }
      // Remove all modules which are not available for the user
      let routeName = menu?.slug?.toLowerCase().replace(" form", ""); //TODO: need to change

      return this.isAvailableInRoutes(routeName) || menu.isAuth;
    });
  }

  removeChildRoutes(menuData) {
    return menuData.map((route) => {
      // Remove all child routes which are not avalilable for the user
      if (route.children) {
        route.children = route.children.filter((child) => {
          if (ModuleService.disableRoutes?.includes?.(child.slug)) {
            return false;
          }

          if (this.isAdmin || child.isAvailableForAll) {
            return true;
          }

          if (this.isModerator) {
            if (child.title === "Form") {
              return false;
            }
          }

          if (
            !this.availableModules.includes(child.slug?.toLowerCase()) &&
            !this.slugForRoles.includes(child.slug?.toLowerCase())
          ) {
            return false;
          }

          return true;
        });
      }

      return route;
    });
  }

  getNameByRoute() {
    let slug = location.pathname.split("/")[1];
    const route =
      this.routes.filter((item) => item?.path.includes(slug))?.[0] || {};
    let name = ModuleService.getNameBySlug(slug);
    if (name) {
      route.moduleName = name;
    }
    return route;
  }

  updateNameBySlug(menuData) {
    return menuData.map((menu) => {
      menu.title = ModuleService.getNameBySlug(menu.slug) || menu.title;
      menu.text = ModuleService.getNameBySlug(menu.slug) || menu.text;
      if (menu?.children?.length) {
        this.updateNameBySlug(menu.children);
      }
      return menu;
    });
  }

  getAllForms() {
    // console.log(
    //   "this.routes.filter((route) => route.isAddRoute);",

    // );

    return this.sideMenu
      .map((item) => {
        if (item?.children?.length) {
          return item.children;
        }
        return item;
      })
      .flat()
      .filter((r) => !r.skipForQuickAdd && r.navLink);

    return this.routes.filter((route) => route.isAddRoute);
  }

  /**
   * Update the menu data based on user role and accessablity to the available module
   */
  updateSideMenu(menuData) {
    // Removed Parent Module
    menuData = this.removeModuleRoutes(menuData);

    //setting routes that includes setting modules for all roles
    menuData = this.updateSettingRoutes(menuData);

    // Removed Child route
    menuData = this.removeChildRoutes(menuData);

    // Update name according to module name
    menuData = this.updateNameBySlug(menuData);

    return menuData;
  }
}

const RoutingService = new Service();
export default RoutingService;
