import React, { forwardRef } from "react";
import DataTable from "react-data-table-component";
import { Fragment } from "react-is";
import { Card, Input } from "reactstrap";
import startCase from "lodash/startCase";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import PaginationFooter from "./PaginationFooter";

function List({
  basicColumns,
  Mock,
  handlePageChange,
  pagination = {},
  isLoading,
  tableProps = {},
  isStopPaginationFirstCall,
  isBordered = false,
  onRowClick = () => {},
}) {
  const intl = useIntl();

  const BootstrapCheckbox = forwardRef((props, ref) => (
    <div className="form-check">
      <Input type="checkbox" ref={ref} {...props} />
    </div>
  ));

  return (
    <Fragment>
      <Card className={`shadow-none pb-1 ${isBordered && "custom-data-table"}`}>
        <DataTable
          noHeader
          noDataComponent={
            <div className="p-2">
              {intl.formatMessage({
                id: isLoading
                  ? "Loading Data..."
                  : "There are no records to display",
                defaultMessage: isLoading
                  ? "Loading Data..."
                  : "There are no records to display",
              })}
            </div>
          }
          columns={basicColumns.map((column) => {
            if (column.name) {
              if (!column.disableStartCase) {
                column.name = intl.formatMessage({
                  id: startCase(column.name?.toLowerCase()),
                  defaultMessage: startCase(column.name?.toLowerCase()),
                });
              } else {
                column.name = intl.formatMessage({
                  id: column.name,
                  defaultMessage: column.name,
                });
              }
            } else {
              column.name = "";
            }
            return column;
          })}
          data={Mock}
          onRowClicked={onRowClick}
          selectableRowsComponent={BootstrapCheckbox}
          {...tableProps}
        />
      </Card>

      <PaginationFooter
        pagination={pagination}
        handlePageChange={handlePageChange}
        isStopPaginationFirstCall={isStopPaginationFirstCall}
      />
    </Fragment>
  );
}

export default observer(List);
