import { Fragment } from "react";
import {
  CatastrophicIcon,
  ChevronUpIcon,
  CriticalIcon,
  DoubleArrowUpYellowIcon,
  MajorIcon,
  MinorIcon,
  modrateIcon,
  PadIcon,
  SearchGreenIcon,
  ThreeArrowUpIcon,
  TicketIcon,
} from "../../../../assets/wasfaty/SVG";
import { SC } from "../../../wasfaty/Api/serverCall";
import { Col, Row } from "reactstrap";
import { Skeleton } from "primereact/skeleton";

export const PercentageCardSkeleton = () => (
  <Fragment>
    <Row className="mb-2">
      <Col md="12">
        <Skeleton width="" height="16rem"></Skeleton>
      </Col>
    </Row>
    <Row className="mb-2">
      <Col>
        <Skeleton width="" height="132px"></Skeleton>
      </Col>
      <Col>
        <Skeleton width="" height="132px"></Skeleton>
      </Col>
    </Row>
    <Row className="mb-2">
      <Col md="12">
        <Skeleton width="" height="29rem"></Skeleton>
      </Col>
    </Row>
  </Fragment>
);

export const SitesDataSkeleton = () =>
  [...new Array(10)].map((_, index) => (
    <div key={index} style={{ paddingInline: 4, marginBlock: 10 }}>
      <Skeleton height="1.2rem" />
    </div>
  ));

export const CardsGroupSkeleton = () => (
  <Fragment>
    <Row className="mb-2 gy-1 gx-1">
      <Col md="12" lg="4">
        <Skeleton width="" height="15rem"></Skeleton>
      </Col>
      <Col md="12" lg="4">
        <Skeleton width="" height="15rem"></Skeleton>
      </Col>
      <Col md="12" lg="4">
        <Skeleton width="" height="15rem"></Skeleton>
      </Col>
    </Row>
  </Fragment>
);

export const getParams = ({
  selectedDate,
  selectedRegion,
  selectedCluster,
  formId,
}) => {
  const data = {
    start_date: selectedDate?.[0] || null,
    end_date: selectedDate?.[1] || null,
    date_type: selectedDate?.[2] || null,
    region_id: selectedRegion?._id || null,
    cluster_id: selectedCluster?._id || null,
    form_id: formId || null,
  };

  return Object.fromEntries(Object.entries(data).filter(([_, value]) => value));
};

export const getData = ({
  filterParams,
  setLoading,
  setData,
  setStanderedSubmission1,
  setStanderedSubmission2,
}) => {
  setLoading(true);
  setData(null);
  SC.getCall({
    url: `dashboard/compliance`,
    params: filterParams,
  }).then((res) => {
    if (res?.data) {
      const {
        catasatrophicStandardsCount = 0,
        majorStandardsCount,
        moderateStandardsCount,
        minorStandardsCount,
        regionalCompliance,
        ticketsCount,
        notComplayStandardsCount,
        correctedStandardsCount,
        correctionPercentage,
      } = res?.data?.standardsSubmission;

      const standeredSubmission1 = [
        {
          count: catasatrophicStandardsCount,
          name: "Catastrophic",
          icon: CatastrophicIcon,
        },
        { count: majorStandardsCount, name: "Major", icon: MajorIcon },
        { count: moderateStandardsCount, name: "Modrate", icon: modrateIcon },
        { count: minorStandardsCount, name: "Minor", icon: MinorIcon },
      ];

      const standeredSubmission2 = [
        {
          count: `${regionalCompliance}%`,
          name: "Regional Compliance",
          icon: SearchGreenIcon,
        },
        {
          count: notComplayStandardsCount,
          name: "Total not compliance standards",
          icon: PadIcon,
        },
        { count: ticketsCount, name: "Ticket", icon: TicketIcon },
        {
          count: correctedStandardsCount,
          name: "Total corrected standards",
          icon: PadIcon,
        },
        {
          count: `${correctionPercentage}%`,
          name: "Correction percentage",
          icon: PadIcon,
        },
      ];

      setStanderedSubmission1(standeredSubmission1);
      setStanderedSubmission2(standeredSubmission2);
      setData(res.data);
      setLoading(false);
    }
  });
};

const riskLevelStyles = {
  Critical: { textColor: "#710E21", bgColor: "#F8C9D2", icon: CriticalIcon },
  High: { textColor: "#DF1C41", bgColor: "#FDEDF0", icon: ThreeArrowUpIcon },
  Medium: { textColor: "#F17B2C", bgColor: "#FEF3EB", icon: DoubleArrowUpYellowIcon },
  Low: { textColor: "#38C793", bgColor: "#EFFAF6", icon: ChevronUpIcon },
  Default: { textColor: "black", bgColor: "#EFFAF6" },
};

export const getRiskLevelStyle = (riskLevel) =>
  riskLevelStyles[riskLevel] || riskLevelStyles.Default;

export const updateBackground = (value) => {
  let backgroundColor;
  if (value < 50) {
    backgroundColor = "#FDEDF0";
  } else if (value >= 50 && value < 90) {
    backgroundColor = "#FEF7EC";
  } else {
    backgroundColor = "#EFFAF6";
  }
  return backgroundColor;
};

export const updateLinesColor = (value) => {
  let linesColor;
  if (value < 50) {
    linesColor = "#F8C9D2";
  } else if (value >= 50 && value < 90) {
    linesColor = "#FBDFB1";
  } else {
    linesColor = "#38C793";
  }
  return linesColor;
};

export const getTextColor = (value) => {
  let textColor;
  if (value < 50) {
    textColor = "#AF1D38";
  } else if (value >= 50 && value < 90) {
    textColor = "#B47818";
  } else {
    textColor = "#1E8B63";
  }
  return textColor;
};

export const allLines = [
  // Upper right circular positions
  { angle: 8, position: { left: 445, top: 20 }, key: "upper-right" },
  { angle: 15, position: { left: 449, top: 21 }, key: "upper-right" },
  { angle: 20, position: { left: 453, top: 23 }, key: "upper-right" },
  { angle: 30, position: { left: 457, top: 25 }, key: "upper-right" },
  { angle: 35, position: { left: 460, top: 28 }, key: "upper-right" },
  { angle: 40, position: { left: 463, top: 31 }, key: "upper-right" },
  { angle: 45, position: { left: 466, top: 34 }, key: "upper-right" },
  { angle: 50, position: { left: 468, top: 37 }, key: "upper-right" },
  { angle: 55, position: { left: 470, top: 41 }, key: "upper-right" },
  { angle: 60, position: { left: 472, top: 45 }, key: "upper-right" },
  { angle: 65, position: { left: 473, top: 49 }, key: "upper-right" },
  { angle: 70, position: { left: 474, top: 53 }, key: "upper-right" },
  { angle: 75, position: { left: 475, top: 57 }, key: "upper-right" },
  { angle: 80, position: { left: 476, top: 61 }, key: "upper-right" },
  { angle: 85, position: { left: 476, top: 65 }, key: "upper-right" },

  // Lower Right circular positions
  { angle: 90, position: { left: 476, top: 69 }, key: "lower-right" },
  { angle: 95, position: { left: 476, top: 73 }, key: "lower-right" },
  { angle: 100, position: { left: 475, top: 77 }, key: "lower-right" },
  { angle: 105, position: { left: 474, top: 81 }, key: "lower-right" },
  { angle: 110, position: { left: 473, top: 85 }, key: "lower-right" },
  { angle: 115, position: { left: 472, top: 89 }, key: "lower-right" },
  { angle: 120, position: { left: 470, top: 93 }, key: "lower-right" },
  { angle: 125, position: { left: 468, top: 97 }, key: "lower-right" },
  { angle: 130, position: { left: 466, top: 100 }, key: "lower-right" },
  { angle: 135, position: { left: 463, top: 103 }, key: "lower-right" },
  { angle: 140, position: { left: 460, top: 106 }, key: "lower-right" },
  { angle: 145, position: { left: 457, top: 109 }, key: "lower-right" },
  { angle: 150, position: { left: 453, top: 111 }, key: "lower-right" },
  { angle: 155, position: { left: 449, top: 113 }, key: "lower-right" },
  { angle: 160, position: { left: 445, top: 115 }, key: "lower-right" },

  ...Array(101)
    .fill({})
    .map((_) => ({
      position: { top: 116, left: 123, marginRight: 2, topOffset: 116 },
      isInline: true, // Bottom inline lines
      isInlineBottom: true,
    })),

  // Updated Lower left circular positions
  { angle: 8, position: { left: 38, top: 115 }, key: "lower-left" },
  { angle: 15, position: { left: 34, top: 113 }, key: "lower-left" },
  { angle: 20, position: { left: 30, top: 111 }, key: "lower-left" },
  { angle: 30, position: { left: 26, top: 109 }, key: "lower-left" },
  { angle: 35, position: { left: 23, top: 106 }, key: "lower-left" },
  { angle: 40, position: { left: 20, top: 103 }, key: "lower-left" },
  { angle: 45, position: { left: 17, top: 100 }, key: "lower-left" },
  { angle: 50, position: { left: 15, top: 97 }, key: "lower-left" },
  { angle: 55, position: { left: 13, top: 93 }, key: "lower-left" },
  { angle: 60, position: { left: 11, top: 89 }, key: "lower-left" },
  { angle: 65, position: { left: 10, top: 85 }, key: "lower-left" },
  { angle: 70, position: { left: 9, top: 81 }, key: "lower-left" },
  { angle: 75, position: { left: 8, top: 77 }, key: "lower-left" },
  { angle: 80, position: { left: 7, top: 73 }, key: "lower-left" },
  { angle: 85, position: { left: 7, top: 69 }, key: "lower-left" },

  // Updated Upper left circular positions
  { angle: -85, position: { left: 7, top: 65 }, key: "upper-left" },
  { angle: -80, position: { left: 7, top: 61 }, key: "upper-left" },
  { angle: -75, position: { left: 8, top: 57 }, key: "upper-left" },
  { angle: -70, position: { left: 9, top: 53 }, key: "upper-left" },
  { angle: -65, position: { left: 10, top: 49 }, key: "upper-left" },
  { angle: -60, position: { left: 11, top: 45 }, key: "upper-left" },
  { angle: -55, position: { left: 13, top: 41 }, key: "upper-left" },
  { angle: -50, position: { left: 15, top: 37 }, key: "upper-left" },
  { angle: -45, position: { left: 17, top: 34 }, key: "upper-left" },
  { angle: -40, position: { left: 20, top: 31 }, key: "upper-left" },
  { angle: -35, position: { left: 23, top: 28 }, key: "upper-left" },
  { angle: -30, position: { left: 26, top: 25 }, key: "upper-left" },
  { angle: -20, position: { left: 30, top: 23 }, key: "upper-left" },
  { angle: -15, position: { left: 34, top: 21 }, key: "upper-left" },
  { angle: -8, position: { left: 38, top: 20 }, key: "upper-left" },

  ...Array(101)
    .fill({})
    .map((_) => ({
      position: { top: 0, left: 123, marginRight: 2, topOffset: 0 },
      isInline: true,
      isInlineTop: true,
    })),
];
