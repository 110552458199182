// ** React Imports
import { Fragment, useState, useRef, useMemo } from "react";

// ** Third Party Components
import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";

// ** Vertical Menu Components
import VerticalMenuHeader from "./VerticalMenuHeader";
import VerticalNavMenuItems from "./VerticalNavMenuItems";
// import Logo from "../../../../../assets/images/MOH_W_AM.png";

import { menuToggler } from "../../../../../assets/wasfaty/SVG";
import { getUserData } from "@utils";
import RoutingService from "../../../../../views/wasfaty/services/RoutingService";
import { useDispatch } from "react-redux";
import { handleMenuCollapsed } from "../../../../../redux/layout";

const Sidebar = (props) => {
  // ** Props
  const {
    menuCollapsed,
    menu,
    skin,
    menuData,
    setMenuVisibility,
    windowWidth,
  } = props;
  const user = getUserData();

  // ** States
  const [groupOpen, setGroupOpen] = useState([]);
  const [groupActive, setGroupActive] = useState([]);
  const [currentActiveGroup, setCurrentActiveGroup] = useState([]);
  const [activeItem, setActiveItem] = useState(null);

  // ** Menu Hover State
  const [menuHover, setMenuHover] = useState(false);

  // ** Ref
  const shadowRef = useRef(null);
  const dispatch = useDispatch();
  // ** Function to handle Mouse Enter
  const onMouseEnter = () => {
    // setMenuHover(true);
  };

  // ** Scroll Menu
  const scrollMenu = (container) => {
    if (shadowRef && container.scrollTop > 0) {
      if (!shadowRef.current.classList.contains("d-block")) {
        shadowRef.current.classList.add("d-block");
      }
    } else {
      if (shadowRef.current.classList.contains("d-block")) {
        shadowRef.current.classList.remove("d-block");
      }
    }
  };
  let type = user?.type?.toLowerCase();

  //  Admin: Default menu creation
  const menuList = useMemo(() => {
    return RoutingService.updateSideMenu(menuData).filter(
      (menu) => !menu.extraMenu
    );
  }, [menuData]);

  const extraMenur = useMemo(() => {
    return RoutingService.updateSideMenu(menuData).filter(
      (menu) => menu.extraMenu
    );
  }, [menuData]);

  return (
    <Fragment>
      <div
        style={{ backgroundColor: "#fff" }}
        className={classnames(
          "main-menu menu-fixed menu-accordion menu-shadow",
          {
            expanded: menuHover || menuCollapsed === false,
            "menu-light": skin !== "semi-dark" && skin !== "dark",
            "menu-dark": skin === "semi-dark" || skin === "dark",
          }
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setMenuHover(false)}
      >
        {menu ? (
          menu({ ...props })
        ) : (
          <Fragment>
            <figure
              className={classnames("menu-toggler", {
                "rotate-toggler": menuCollapsed,
              })}
              style={{ display: windowWidth <= 1200 ? "none" : "block" }}
              onClick={() =>
                windowWidth <= 1200
                  ? setMenuVisibility(true)
                  : dispatch(handleMenuCollapsed(!menuCollapsed))
              }
            >
              {menuToggler}
            </figure>
            {/* Vertical Menu Header */}
            <VerticalMenuHeader
              setGroupOpen={setGroupOpen}
              menuHover={menuHover}
              {...props}
            />
            {/* Vertical Menu Header Shadow */}
            <div className="" ref={shadowRef}></div>
            <div className="d-flex flex-column">
              {/* Perfect Scrollbar */}

              <div
                className="menu-section"
                style={{
                  height: window.innerHeight > 700 ? "65vh" : "50vh",
                  overflow: "hidden",
                  marginTop: 10,
                }}
              >
                <PerfectScrollbar options={{ wheelPropagation: false }}>
                  <ul className="navigation">
                    <VerticalNavMenuItems
                      items={menuList}
                      menuData={menuList}
                      menuHover={menuHover}
                      groupOpen={groupOpen}
                      activeItem={activeItem}
                      groupActive={groupActive}
                      setGroupOpen={setGroupOpen}
                      menuCollapsed={menuCollapsed}
                      setActiveItem={setActiveItem}
                      setGroupActive={setGroupActive}
                      currentActiveGroup={currentActiveGroup}
                      setCurrentActiveGroup={setCurrentActiveGroup}
                    />
                  </ul>
                </PerfectScrollbar>
              </div>

              <div
                className="extra-menu-section"
                style={{
                  height: "15vh",
                  overflow: "hidden",
                  marginTop: "20px",
                }}
              >
                <PerfectScrollbar options={{ wheelPropagation: false }}>
                  <ul className="navigation">
                    <VerticalNavMenuItems
                      items={extraMenur}
                      menuData={extraMenur}
                      menuHover={menuHover}
                      groupOpen={groupOpen}
                      activeItem={activeItem}
                      groupActive={groupActive}
                      setGroupOpen={setGroupOpen}
                      menuCollapsed={menuCollapsed}
                      setActiveItem={setActiveItem}
                      setGroupActive={setGroupActive}
                      currentActiveGroup={currentActiveGroup}
                      setCurrentActiveGroup={setCurrentActiveGroup}
                    />
                  </ul>
                </PerfectScrollbar>
              </div>
              <ul>
                <li className="sidebar_img fixed-bottom">
                  {!menuCollapsed || menuHover ? (
                    <p>Powered by Ascend Solutions</p>
                  ) : null}
                </li>
              </ul>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Sidebar;
