import { makeAutoObservable } from "mobx";
import { AuthService, SiteService, UserService } from "../services";
import get from "lodash/get";
import debounce from "lodash/debounce";
import startCase from "lodash/startCase";
import { getRoleLabel } from "../services/AuthService";

export class UserSearchModal {
  users = [];
  isLoading = false;
  search = "";
  sideIds = [];

  constructor(type, siteIds) {
    this.sideIds = siteIds;
    this.getUsers(type);
    makeAutoObservable(this);
  }

  getUsers = (type) => {
    // // Find all sites to present in the dropdown menu
    let search = this.search;

    this.isLoading = true;
    this.users = [];
    UserService.getUsers({
      search,
      type,
      site_ids: this.sideIds,
    })
      .then((res) => {
        let _users = get(res, "data.data");
        if (_users?.length) {
          this.users = _users.map((user) => {
            let label = `${user.name} (${user.email}) - ${getRoleLabel(
              user.type
            )}`;

            return {
              ...user,
              label,
              value: user._id,
            };
          });
        }
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  };

  onSearch = debounce((search, type) => {
    if (search) {
      this.search = search;
      this.getUsers(type);
    }
  }, 1000);
}
