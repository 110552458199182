import { Fragment, useEffect, useRef, useState } from "react";
import { Row } from "reactstrap";
import Dropdown from "../../wasfaty/Helper/DropdownB";
import { ScheduleTaskService } from "../../wasfaty/services";
import ExportExcel from "../../components/ExportExcel";
import "./styles.scss";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ListHeader from "../../../@core/components/ListHeader/ListHeader";
import ScheduleCardView from "./components/scheduleTask/ScheduleCardView";
import { ScheduleTaskModal } from "../../wasfaty/Models/ScheduleTaskModal";
import PaginationFooter from "../../wasfaty/components/listing/PaginationFooter";
import AuthUser from "../../wasfaty/services/AuthService";
import { Segmented } from "antd";
const MySwal = withReactContent(Swal);

function ScheduleAvailability() {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const isFirstRender = useRef(true);
  const [tab, setTab] = useState("active");
  const [count, setCount] = useState({ active: 0, deleted: 0 });
  const [pagination, setPagination] = useState({
    page: 0,
    pageCount: 0,
    perPage: 10,
    to: 0,
    totalPages: 0,
  });

  const [filter, setFilter] = useState({
    search: "",
    fromDate: "",
    toDate: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the API call on the first render
      isFirstRender.current = false;
      return;
    }
    getAllItems();
  }, [tab]);

  const getAllItems = (query = {}) => {
    setIsLoading(true);
    setLoading(true);

    const params = {
      page: (query?.selected || 0) + 1,
      per_page: query?.perPage || 10,
      ...filter, // Include filters
    };

    ScheduleTaskService.find(params)
      .then((res) => {
        const activeData = res?.data?.data;
        const deletedData = res?.data?.deleted;

        if (activeData || deletedData) {
          const isTabActive = tab === "active"; // Assuming `tab` holds the active tab's name
          const currentData = isTabActive ? activeData : deletedData;

          if (currentData) {
            // Map data to ScheduleTaskModal
            setItems(
              currentData.data.map(
                (schedule) => new ScheduleTaskModal(schedule)
              )
            );

            // Extract and calculate pagination details
            const { current_page, per_page, total, to } = currentData;
            const pageCount = Math.ceil(total / per_page);

            setPagination({
              page: current_page,
              perPage: per_page,
              totalPages: total,
              pageCount,
              to,
            });
          }

          // Update counts for both tabs
          setCount({ active: activeData.total, deleted: deletedData.total });
        }
      })
      .catch(() => {
        // Handle error (optional logging can be added here)
      })
      .finally(() => {
        // Ensure loading states are reset
        setLoading(false);
        setIsLoading(false);
      });
  };

  const onTabChange = (newTabVal) => {
    setPagination({
      page: 1,
      perPage: 10,
      totalPages: 0,
      pageCount: 0,
      to: 0,
    });
    setTab(newTabVal);
  };

  const DeleteData = (id) => {
    MySwal.fire({
      html: `
        <p class='confirm-class-head'>Are you sure you want to delete?</p>
        <textarea 
          id='delete-reason' 
          class='form-control' 
          placeholder='Enter reason for deletion' 
          rows='3'></textarea>
      `,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ms-1",
      },
      preConfirm: () => {
        const reason = document.getElementById("delete-reason").value;
        if (!reason) {
          Swal.showValidationMessage("Please enter a reason for deletion");
        }
        return reason;
      },
      buttonsStyling: false,
    }).then(({ isConfirmed, value: reason }) => {
      if (isConfirmed) {
        ScheduleTaskService.delete(id, reason).then((res) => {
          setCount((count) => {
            return {
              active: count.active - 1,
              deleted: count.deleted + 1
            }
          })
          if (res.status === 200) {
            getAllItems({
              selected: pagination.page - 1,
            });
            toast.success(res.data.data);
          } else {
            if (res.errors) {
              toast.error(error);
            }
          }
        });
      }
    });
  };

  return (
    <Fragment>
      <ListHeader
        pagination={pagination}
        exportComponent={
          null
          // <ExportExcel
          //   apiCall={true}
          //   endPoint={`${ScheduleTaskService.route}`}
          //   fileName={"ScheduleTask"}
          //   exportFilter={{ per_page: pagination.totalPages, page: 0 }}
          //   handleExportContent={({ data }) => {
          //     return ScheduleTaskService.mapExportData(data.data || []);
          //   }}
          // />
        }
        addFormUrl={
          AuthUser.isAdmin ||
          AuthUser.isSupervisor ||
          AuthUser.isRegionalRepresentatives
            ? "/Schedule-Task-Availability/Add"
            : false
        }
        dashboardUrl="/Schedule-Task-Availability/Schedule-Calander"
        dashboardText="Calender"
        filter={filter}
        setFilter={setFilter}
        getData={getAllItems}
        loading={loading}
        addButtonText="Add Schedule"
      />
      <Row>
        <Segmented
          value={tab}
          style={{ marginBottom: 8 }}
          onChange={onTabChange}
          options={[
            {
              label: (
                <span className="active-span-1">
                  Active <span className="inner-span">{count.active}</span>
                </span>
              ),
              value: "active",
            },
            {
              label: (
                <span className="deleted-span-1">
                  Deleted <span className="inner-span">{count.deleted}</span>
                </span>
              ),
              value: "deleted",
            },
          ]}
        />
        {isLoading ? (
          <Row className="d-flex align-items-center justify-content-center">
            <h5 className="w-auto">Loading Data...</h5>
          </Row>
        ) : (
          <>
            <Row className="d-flex align-items-center justify-content-center">
              {!items?.length && !isLoading && (
                <h5 className="w-auto">No Schedule Available!</h5>
              )}
            </Row>
            <ScheduleCardView
              tab={tab}
              schedules={items}
              DeleteData={DeleteData}
            />
          </>
        )}
        <PaginationFooter
          pagination={pagination}
          handlePageChange={getAllItems}
        />
      </Row>
    </Fragment>
  );
}

export default ScheduleAvailability;
