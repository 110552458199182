import React, { Fragment, useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import { Skeleton } from "primereact/skeleton";

import {
  CardsGroupSkeleton,
  getData,
  getParams,
  PercentageCardSkeleton,
} from "./RegionalDashboardHelper";
import FilterBar from "./Cards/FilterBar";
import MapViewCard from "./Cards/MapView";
import CardsGroup from "./Cards/CardsGroup";
import CountsTableCard from "./Cards/CountsTable";
import RegionalComplianceCard from "./Cards/RegionalCompliance";
import SitesAndComplaintCard from "./Cards/SitesAndComplaint";
import CirculerProgressBarCard from "./Cards/CirculerProgressBar";

import "./style.scss";

const RegionalDashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [standeredSubmission1, setStanderedSubmission1] = useState(null);
  const [standeredSubmission2, setStanderedSubmission2] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedCluster, setSelectedCluster] = useState(null);
  const [formId, setFormId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    getDashboardData();
  }, [selectedRegion, selectedCluster, selectedDate, formId]);

  const getDashboardData = () => {
    getData({
      filterParams: getFilterParams(),
      setLoading,
      setData,
      setStanderedSubmission1,
      setStanderedSubmission2,
    });
  };

  const reset = () => {
    setFormId(null);
  };

  const getFilterParams = () => {
    return getParams({
      selectedDate,
      selectedRegion,
      selectedCluster,
      formId,
    });
  };

  return (
    <Fragment>
      <Card id="regional_dashboard">
        <FilterBar
          selectedDate={selectedDate}
          selectedRegion={selectedRegion}
          selectedCluster={selectedCluster}
          setFormId={setFormId}
          setIsReset={setIsReset}
          getDashboardData={getDashboardData}
          setSelectedRegion={setSelectedRegion}
          setSelectedCluster={setSelectedCluster}
          setSelectedDate={setSelectedDate}
        />

        <Row>
          <Col>
            {loading && !data ? (
              <PercentageCardSkeleton />
            ) : (
              <Fragment>
                <CirculerProgressBarCard
                  sitesSubmission={data?.sitesSubmission}
                  sectionName={data?.sitesSubmission.sectionName}
                  selectedDate={selectedDate}
                />
                <SitesAndComplaintCard
                  sitesSubmission={data?.sitesSubmission}
                />
                <RegionalComplianceCard
                  isReset={isReset}
                  setIsReset={setIsReset}
                  setFormId={setFormId}
                  submissions={data?.formsSubmission?.submissions}
                  sectionName={data?.formsSubmission?.sectionName}
                />
              </Fragment>
            )}
          </Col>

          <Col>
            {loading && !data ? (
              <Row className="mb-2">
                <Col md="12">
                  <Skeleton width="" height="27rem"></Skeleton>
                </Col>
              </Row>
            ) : (
              <CountsTableCard
                standeredSubmission1={standeredSubmission1}
                standeredSubmission2={standeredSubmission2}
              />
            )}
            <MapViewCard
              loading={loading}
              regions={data?.regionsSubmission?.submissions}
            />
          </Col>
        </Row>

        {loading && !data ? (
          <CardsGroupSkeleton />
        ) : (
          <CardsGroup
            cardsData={data?.cardsData}
            getFilterParams={getFilterParams}
            getDashboardData={getDashboardData}
          />
        )}
      </Card>
    </Fragment>
  );
};

export default RegionalDashboard;
