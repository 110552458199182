import { Resources } from "../Resource";

import { SC } from "../Api/serverCall";
class Service extends Resources {
  route = "scheduleSelfAssessment";
  routes = {
    find: "",
    create: "/create",
    show: "/show",
    tasks: "/tasks",
    update: "/update",
    delete: "/delete",
    availableSites: "/get-available-sites",
  };

  constructor() {
    super(arguments);
  }

  getAvailableSites = async (params) => {
    try {
      let res = await SC.getCall({
        url: "scheduleSelfAssessment/get-available-sites",
        params,
      });
      if (res?.data.data) {
        return res?.data;
      }
    } catch (e) {
      return [];
    }
  };

  reassign = async ({ user_id, task_id }) => {
    try {
      let res = await SC.getCall({
        url: "scheduleSelfAssessment/reassign",
        params: { user_id, task_id },
      });
      if (res?.data.data) {
        return res?.data;
      }
    } catch (e) {
      return [];
    }
  };
}

const ScheduleSelfAssessmentServices = new Service();
export default ScheduleSelfAssessmentServices;
