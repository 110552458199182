import React, { useEffect, useState, useCallback, useRef } from "react";
import { Input, Modal, ModalBody, Spinner } from "reactstrap";
import get from "lodash/get";
import List from "../../../../wasfaty/components/listing/List";
import { CrossIocn, SmallCrossIocn } from "../../../../../assets/wasfaty/SVG";
import { customStylesForSelfAssessmentModalTable } from "../../../../../utility/constants";
import { SC } from "../../../../wasfaty/Api/serverCall";
import { debounce } from "lodash";
import "./style.scss";
import { useNavigate } from "react-router-dom";

const SitesModal = ({
  modal,
  toggle,
  getDataBySite = () => {},
  getFilterParams,
}) => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [firstRender, setFirstRender] = useState(true);

  const [pagination, setPagination] = useState({
    page: 1,
    pageCount: 0,
    perPage: 10,
    totalPages: 0,
    to: 0,
  });

  useEffect(() => {
    if (modal && firstRender) {
      setFirstRender(false);
      return;
    }

    if (modal) {
      fetchSites();
    }
  }, [modal]);

  const fetchSites = useCallback(
    (query = {}) => {
      setLoading(true);
      const {
        selected = 0,
        perPage = pagination.perPage,
        value = inputValue,
      } = query;

      const params = {
        page: selected + 1,
        per_page: perPage,
        search: value,
        ...getFilterParams(),
      };

      SC.getCall({ url: `dashboard/compliance-sites`, params })
        .then((res) => {
          const data = res?.data;
          if (data) {
            setItems(data.data);
            const pageCount = Math.ceil(data.total / data.per_page);
            setPagination({
              page: data.current_page,
              pageCount,
              perPage: data.per_page,
              totalPages: data.total,
              to: data.to,
            });
          }
        })
        .finally(() => setLoading(false));
    },
    [inputValue, pagination.perPage]
  );

  const handleSearch = useRef(
    debounce((value) => fetchSites({ value }), 1000)
  ).current;

  const handleClose = () => {
    toggle();
    resetState();
  };

  const resetState = () => {
    setItems([]);
    setInputValue("");
    setFirstRender(true);
    setPagination({
      page: 1,
      pageCount: 0,
      perPage: pagination.perPage,
      totalPages: 0,
      to: 0,
    });
  };

  const getDataBySiteId = (site) => {
    handleClose();
    getDataBySite(site);
  };

  const gotToForm = (row) => {
    const path = `/${row.form_id}/Form-Builder/${row.form_submition_id}`;
    navigate(path);
  };

  const basicColumns = [
    {
      name: "Report No",
      width: "20%",
      cell: (row) => (
        <span onClick={() => gotToForm(row)} className="table-cells">
          {get(row, "report_no", "N/A")}
        </span>
      ),
    },
    {
      name: "Site Name",
      width: "40%",
      cell: (row) => (
        <span onClick={() => getDataBySiteId(row)}>
          {get(row, "site_name_ar", get(row, "site_name", "N/A"))}
        </span>
      ),
    },
    {
      name: "Percentage",
      width: "20%",
      cell: (row) => (
        <span onClick={() => getDataBySiteId(row)}>
          {get(row, "percentage", "N/A")}
        </span>
      ),
    },
    {
      name: "Submission Date",
      width: "20%",
      cell: (row) => (
        <span onClick={() => getDataBySiteId(row)}>
          {get(row, "form_submition_date", "N/A")}
        </span>
      ),
    },
  ];

  return (
    <Modal
      centered
      className="modal_main"
      isOpen={modal}
      toggle={handleClose}
      unmountOnClose
      style={{ "--modal-max-width": "65vw" }}
    >
      <ModalBody>
        <div className="modal_body">
          <div className="row">
            <div style={{ width: 400 }}>
              <div className="search_box">
                <Input
                  className="search_input"
                  placeholder="Search"
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  // onKeyDown={(e) => {
                  //   if (e.key === "Enter" && inputValue) {
                  //     fetchSites();
                  //   }
                  // }}
                />
                {loading ? (
                  <div className="searchIcon">
                    <Spinner size="sm" />
                  </div>
                ) : (
                  inputValue && (
                    <div
                      className="searchIcon"
                      onClick={() => {
                        setInputValue("");
                        fetchSites({ value: "" });
                      }}
                    >
                      {SmallCrossIocn}
                    </div>
                  )
                )}
              </div>
            </div>
            <div onClick={handleClose} className="cross_icon">
              {CrossIocn}
            </div>
          </div>

          <div className="hr" />

          <List
            isLoading={loading}
            basicColumns={basicColumns}
            Mock={items}
            pagination={pagination}
            handlePageChange={fetchSites}
            onRowClick={getDataBySiteId}
            height="50vh"
            tableProps={{
              customStyles: customStylesForSelfAssessmentModalTable,
            }}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SitesModal;
