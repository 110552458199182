import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Button, Tag, Checkbox, Menu, Dropdown, DatePicker, Space } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

import {
  ChevronDownIcon,
  DownloadIcon,
  RefreshIcon,
  SearchDropdownIcon,
} from "../../../../../assets/wasfaty/SVG";
import VectorX from "../../../../../assets/images/svg/VectorX.svg";
import Lines from "../../../../../assets/images/svg/filter-3-line.svg";
import {
  ClusterService,
  IntlService,
  RegionService,
} from "../../../../wasfaty/services";
import { dropdownValuesForComplianceDashboardFilter } from "../../../../../utility/constants";

const FilterBar = ({
  selectedDate,
  selectedRegion,
  selectedCluster,
  setFormId,
  setIsReset,
  setSelectedRegion,
  setSelectedCluster,
  setSelectedDate,
  getDashboardData,
}) => {
  const [clustersList, setClustersList] = useState(null);
  const [regionList, setRegionList] = useState(null);
  const [isAllRegionsSelected, setIsAllRegionsSelected] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [date, setDate] = useState(null);
  const [dropdownFilterVal, setDropdownFilterVal] = useState(
    dropdownValuesForComplianceDashboardFilter[0]
  );

  useEffect(() => {
    RegionList();
  }, []);

  // Fetch regions list from the server
  const RegionList = () => {
    RegionService.getAllRegions()
      .then((res) => {
        if (res?.data?.data?.length) {
          setRegionList(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log("🚀 ~ getClusterOptions ~ err:", err);
      });
  };

  const toggleAllRegions = (e) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedRegion(null);
      setSelectedCluster(null);
      setIsAllRegionsSelected(checked);
    }
  };

  const toggleRegion = (region) => {
    if (region?._id !== selectedRegion?._id) {
      setSelectedRegion(region);
      setIsAllRegionsSelected(false);
      setSelectedCluster(null);

      ClusterService.getClustersByRegionID(region._id)
        .then((res) => {
          if (res.length) {
            setClustersList(res);
          }
        })
        .catch((err) => {
          console.log("🚀 ~ getClusterOptions ~ err:", err);
        });
    }
  };

  const toggleCluster = (cluster) => {
    if (cluster?._id !== selectedCluster?._id) {
      setSelectedCluster(cluster);
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const clearAll = () => {
    setFormId(null);
    setIsReset(false);
    setSelectedRegion(null);
    setSelectedCluster(null);
    setDate(null);
    setSelectedDate(null);
    setIsAllRegionsSelected(true);
    toggleCollapse();
  };

  const handleDropdownChange = (newDropdownVal) => {
    setDate(null);
    setDropdownFilterVal(newDropdownVal);
  };

  const { RangePicker } = DatePicker;

  return (
    <div className="main_filter_bar">
      <div className="filter_bar">
        <div className={isCollapsed ? "filter_collapse1" : "filter_collapse"}>
          <div
            className="d-flex"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <div className={isCollapsed ? "filter_main" : "filter_main1"}>
              <span>
                <img
                  src={Lines}
                  style={{ width: "22px", marginRight: "10px" }}
                />
              </span>
              <span>Filter</span>
            </div>
            <div className="selected_info">
              {selectedDate && (
                <span className="selected_item">
                  <span style={{ color: "#868C98" }}>
                    {IntlService.m("Date: ")}
                  </span>
                  <span style={{ color: "#525866" }}>
                    {selectedDate[0] + " to " + selectedDate[1]}
                  </span>
                  <span>
                    <CloseOutlined
                      style={{ color: "grey", marginLeft: 5 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedDate(null);
                        setDate(null);
                      }}
                    />
                  </span>
                </span>
              )}

              {selectedRegion && (
                <span className="selected_item">
                  <span style={{ color: "#868C98" }}>
                    {IntlService.m("Region: ")}
                  </span>
                  <span style={{ color: "#525866" }}>
                    {selectedRegion.name}
                  </span>
                  <span>
                    <CloseOutlined
                      style={{ color: "grey", marginLeft: 5 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedRegion(null);
                        setSelectedCluster(null);
                        setIsAllRegionsSelected(true);
                      }}
                    />
                  </span>
                </span>
              )}

              {selectedCluster && (
                <span className="selected_item">
                  <span style={{ color: "#868C98" }}>
                    {IntlService.m("Cluster: ")}
                  </span>
                  <span style={{ color: "#525866" }}>
                    {selectedCluster.name}
                  </span>
                  <span>
                    <CloseOutlined
                      style={{ color: "grey", marginLeft: 5 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedCluster(null);
                      }}
                    />
                  </span>
                </span>
              )}
            </div>
          </div>

          <Tag className="close_filter" onClick={toggleCollapse}>
            {isCollapsed ? (
              <>
                <PlusOutlined className="icon_add" />{" "}
                {IntlService.m("Add Filters")}
              </>
            ) : (
              <>
                <CloseOutlined className="icon_add" />{" "}
                {IntlService.m("Close Filters")}
              </>
            )}
          </Tag>
        </div>
        {!isCollapsed && (
          <div className="inner_dev">
            <div className="filter_header">
              <div className="left_filters">
                {
                  (regionList.length > 1) && (
                    <div className="filter_options">
                      <Tag className="all_tag">
                        <Checkbox
                          checked={isAllRegionsSelected}
                          onChange={toggleAllRegions}
                          className="checkbox"
                        />
                        {IntlService.m("All Regions")}
                      </Tag>
                    </div>
                  )
                }
                <div className="dates">
                  <div className="search_box">
                    {/* searchbar dropdown */}
                    <Dropdown
                      trigger={["click"]}
                      overlay={
                        <Menu>
                          {dropdownValuesForComplianceDashboardFilter.map(
                            (item, index) => (
                              <Menu.Item
                                key={index + 1}
                                onClick={() => handleDropdownChange(item)}
                              >
                                {IntlService.m(item.value)}
                              </Menu.Item>
                            )
                          )}
                        </Menu>
                      }
                    >
                      <div className="dropdown">
                        <div className="dropdown_icon">
                          {SearchDropdownIcon}
                        </div>
                        <div className="dropdown_val">
                          {IntlService.m(dropdownFilterVal.value)}
                        </div>
                        <div className="chevron">{ChevronDownIcon}</div>
                      </div>
                    </Dropdown>
                    <div className="filter_date">
                      <Space direction="vertical" size={12}>
                        <RangePicker
                          className="range_picker"
                          picker={
                            dropdownFilterVal.picker === "day"
                              ? "date"
                              : dropdownFilterVal.picker
                          }
                          value={
                            date && date.length === 2
                              ? [dayjs(date[0]), dayjs(date[1])]
                              : null
                          }
                          onChange={(_, info) => {
                            const hasValidDates =
                              info && info.length === 2 && info[0] && info[1];
                            if (hasValidDates) {
                              setSelectedDate([
                                ...info,
                                dropdownFilterVal.picker,
                              ]);
                              setDate(info);
                            } else {
                              setSelectedDate(null);
                              setDate(null);
                            }
                          }}
                          disabledDate={(current) =>
                            current && current > dayjs().endOf("day")
                          }
                        />
                      </Space>
                    </div>
                  </div>
                </div>{" "}
              </div>
              <Button className="clear_all" onClick={clearAll}>
                <span>
                  <img
                    src={VectorX}
                    style={{ width: "15px", marginRight: "5px" }}
                  />
                </span>
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginTop: "2px",
                    color: "#375DFB",
                  }}
                >
                  Clear all
                </span>
              </Button>
            </div>

            <div className="filter_section">
              <div className="filter_title">{IntlService.m("Regions")}</div>
              <div className="filter_options">
                {regionList?.map((region, index) => (
                  <Tag
                    key={index}
                    className={
                      selectedRegion?._id === region._id
                        ? "selected_tags"
                        : "unselected_tags"
                    }
                    onClick={() => toggleRegion(region)}
                  >
                    {region.name}
                  </Tag>
                ))}
              </div>
            </div>

            <div className="filter_section">
              <div className="filter_title">{IntlService.m("Clusters")}</div>
              {selectedRegion && (
                <div className="filter_options">
                  {clustersList?.map((cluster, index) => (
                    <Tag
                      key={index}
                      className={
                        selectedCluster?._id === cluster._id
                          ? "selected_tags"
                          : "unselected_tags"
                      }
                      onClick={() => toggleCluster(cluster)}
                    >
                      {cluster.name}
                    </Tag>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="icons">
        <div className="icon" onClick={() => getDashboardData()}>
          {RefreshIcon}
        </div>
        {/* <div className="icon">{DownloadIcon}</div> */}
      </div>
    </div>
  );
};

export default FilterBar;
